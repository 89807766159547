import * as React from 'react';

import { Contact, FaqSection, HeroSection, PropertyTaxServices } from '../components/landing';
import { Footer, GoogleNoIndex, PageTitle } from '../components/shared';
import { BusinessReviews } from '../components/home';

const LowerYourPropertyTaxesPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <HeroSection leadSource="AdWords" />
      <PropertyTaxServices />
      <div id="landing-reviews" style={{ visibility: 'hidden' }}>
        <BusinessReviews />
      </div>
      <div id="landing-faq" style={{ display: 'none' }}>
        <FaqSection />
      </div>
      <Contact />
      <Footer />
      <div className="sticky-landing">
        <p>Unlock Your Property Tax Savings in 2024</p>
        <a href="/signup">GET STARTED</a>
      </div>
    </>
  );
};

export default LowerYourPropertyTaxesPage;
